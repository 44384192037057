<template>
  <v-navigation-drawer
    class="AdminNavDrawer"
    :mini-variant="bMiniVariant"
    height="100vh"
    color="#ffffff"
    fixed
  >
    <!-- Start static list item for logo -->
    <v-list-item
      :style="'background: url(/static/Imagen3.png) center center / cover'"
      color="#ffffff"
    >
      <!-- Container for format -->
      <v-container style="margin-top: 15%; margin-bottom: 15%">
        <v-layout row justify-center>
          <!-- Start logo -->
          <v-layout row fill-height justify-center>
            <v-card
              tile
              flat
              :style="
                bMiniVariant
                  ? 'width: 100%'
                  : 'width: 100px; height: 100px;background: url(/static/Control3_Logo.png) center center / contain no-repeat;'
              "
            >
            </v-card>
          </v-layout>
          <!-- End logo -->
        </v-layout>
      </v-container>
      <!-- End container for format -->
    </v-list-item>
    <!-- End static list item for logo -->
    <v-list
      :style="bMiniVariant ? 'padding-left: 12px' : 'padding-left: 0px'"
      nav
    >
      <!-- Start items iteration -->
      <v-list-item
        width="100%"
        tile
        v-for="item in filteredItems"
        :key="item.title"
        :to="item.href"
        :active-class="'ItemActiveClass'"
        link
      >
        <!-- Start list item icon -->
        <v-list-item-icon>
          <v-icon
            :style="
              item.href == $route.fullPath
                ? 'color: black !important'
                : 'color: black !important'
            "
            dark
          >
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <!-- End list item icon -->
        <!-- Start list item text -->
        <v-list-item-content>
          <v-list-item-title
            :style="
              item.href == $route.fullPath
                ? 'color: black !important'
                : 'color: black !important'
            "
            class="SideMenuItemText"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
        <!-- End list item text -->
      </v-list-item>
      <!-- End items iteration -->
    </v-list>
    <template v-slot:append>
      <v-divider class="sideMenuDivider"></v-divider>
      <div
        class="icon-logout"
        style="
          border-top-width: 1px;
          border-top-color: #b2dcda;
          padding-left: 20px;
        "
      >
        <!-- Start items iteration -->
        <v-list-item @click="logout()" link>
          <!-- Start list item icon -->
          <v-list-item-icon>
            <v-icon
              style="color: black !important; margin-left: -2px !important"
              dark
            >
              {{ texts.logout.icon }}
            </v-icon>
          </v-list-item-icon>
          <!-- End list item icon -->
          <!-- Start list item text -->
          <v-list-item-content>
            <v-list-item-title
              style="color: black !important"
              class="SideMenuItemText"
            >
              {{ texts.logout.text }}
            </v-list-item-title>
          </v-list-item-content>
          <!-- End list item text -->
        </v-list-item>
        <!-- End items iteration -->
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then((resp) => {
          this.$store.commit("toggle_alert", resp);
          this.$router.go();
        })
        .catch((err) => {
          this.$store.commit("toggle_alert", err);
        });
    },
  },
  computed: {
    filteredItems: function () {
      return this.texts.items.filter((routeOption) => {
        if (this.userPermissions.includes("Administrator", 0)) {
          return routeOption;
        } else {
          var exp = this.userPermissions;
          exp = this.userPermissions.replace(/[\[\]']/g, "");
          exp = exp.replace(/"/g, "");
          let arr = "";
          exp.split(",").forEach((e) => {
            if (e == routeOption.permission) {
              arr = e;
            }
          });

          return arr;
        }
      });
    },
    userPermissions() {
      return this.$store.state.permissions;
    },
    bMiniVariant() {
      return this.$store.state.bMiniVariant;
    },
    // return texts for this section
    texts() {
      return texts.spanish.admin.sideMenu;
    },
  },
  data: () => ({
    // image source for login
    sLogoSource: "/static/Control3_Logo.png",
  }),
};
</script>
<style>
.sideMenuDivider {
  border-color: #33958d50 !important;
}
.AdminNavDrawer .v-list--nav {
  padding-right: 0px !important;
}
.AdminNavDrawer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
</style>
<style 
  scoped>
/** style for division between logo and items  */
/** style for menu item text */
.SideMenuItemText {
  font-family: "Poppins", sans-serif !important;
  color: black;
  font-size: 13px !important;
  font-weight: 600 !important;
}
/** change color when route active */
.ItemActiveClass {
  border-radius: 0;
  color: #ffce00;
  background-color: #ffce00 !important;
  padding-left: 35px;
}

.v-list--nav .v-list-item {
  padding-left: 35px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

.v-navigation-drawer--mini-variant .ItemActiveClass {
  padding-left: 10px;
}

.v-navigation-drawer--mini-variant .v-list--nav .v-list-item {
  padding-left: 0px;
}

.v-navigation-drawer--mini-variant .v-navigation-drawer__append {
  padding-left: -10px;
}

.v-navigation-drawer--mini-variant .icon-logout {
  padding-left: 0px !important;
}

.v-navigation-drawer--mini-variant .ItemActiveClass {
  margin-left: -12px;
  padding-left: 12px !important;
}

.v-navigation-drawer--mini-variant .icon-logout .v-list-item {
  padding-left: 15px;
}

.icon-logout {
  padding-left: 0px !important;
}

.icon-logout .v-list-item {
  padding-left: 40px;
}
</style>